import type { InferProps } from 'prop-types';
import type { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import type { RootState } from '@redux/store';

import routeComponentGenerator from '../routeComponentGenerator';

import Base, { type propTypes } from './Base';

export const BaseRoute = connect(
  (state: RootState) => ({ auth: state.auth }),
  {},
)(Base as FunctionComponent<InferProps<typeof propTypes>>);

export default routeComponentGenerator(BaseRoute);
